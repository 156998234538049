import { Button, Grid } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { EventFeedPage } from "./EventFeedPage";

export default function EventFeed({}) {
  const [index, setIndex] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const pages = [];
  for (let i = 0; i < index; i++) {
    pages.push(<EventFeedPage index={i} key={`event-feed-${i}`} />);
  }

  const handleLoadMore = async () => {
    setIsLoading(true);
    setIndex(index + 1);
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  };

  return (
    <Grid container spacing={2}>
      {pages}
      <Grid item xs={12} display="flex" justifyContent="center">
        <AnimatePresence>
          {!isLoading && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.1 }}
              style={{ width: "100%", textAlign: "center" }}
            >
              <Button
                color="secondary"
                variant="outlined"
                onClick={handleLoadMore}
                sx={{
                  mt: 4,
                  width: { xs: "100%", sm: "auto" },
                }}
              >
                Mehr Einträge laden
              </Button>
            </motion.div>
          )}
        </AnimatePresence>
      </Grid>
    </Grid>
  );
}
