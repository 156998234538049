import theme from "@/styles/theme";
import { Chip, Grid } from "@mui/material";

export default function EventCategory({ event, label }) {
  return (
    <Grid item xs="auto">
      <Chip sx={eventCategoryStyles(event.type)} label={label} />
    </Grid>
  );
}

const eventCategoryStyles = (type) => ({
  color: "common.white",
  background:
    type === "band:verified"
      ? theme.palette.secondary.main
      : theme.palette.primary.main,
  fontSize: "0.75rem",
  padding: "0.25rem 0.75rem",
  margin: "1rem 0",
  height: "1.375rem",
  "& .MuiChip-label": {
    padding: 0,
  },
});
