import { StrapiImageAsset } from "@/components/StrapiImageAsset";
import { useDevelopmentProgram, useWorkshop } from "@/lib/api";
import { basierCirleBold } from "@/styles/fonts";
import theme from "@/styles/theme";
import { Box, Grid, Typography } from "@mui/material";
import { alpha } from "@mui/material/styles";
import Link from "next/link";
import EventCategory from "./EventCategory";

export const WorkshopOrDevelopmentProgrammCreatedEvent = ({ event }) => {
  const {
    workshop,
    isLoading: isWorkshopLoading,
    isError: isWorkshopError,
  } = useWorkshop(event.type === "workshop:created" ? event.object_id : null);

  const {
    program,
    isLoading: isProgramLoading,
    isError: isProgramError,
  } = useDevelopmentProgram(
    event.type === "developmentprogram:created" ? event.object_id : null,
  );

  const isLoading = isWorkshopLoading || isProgramLoading;
  const isError = isWorkshopError || isProgramError;
  const data = workshop || program;

  if (isLoading || isError || !data) return <></>;

  const href =
    event.type === "workshop:created"
      ? "foerderung/workshops"
      : `foerderung/foerderfinder#${data?.slug}`;

  return (
    <Link href={href} style={{ textDecoration: "none" }}>
      <Box sx={workshopCreatedEventStyles}>
        <Grid container justifyContent="center" className="content">
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box className="workshop-image">
              <StrapiImageAsset
                strapiImageObject={data?.logo}
                layout="responsive"
                objectFit="contain"
                objectPosition="left"
                width={320}
                height={180}
                fixedHeight={true}
              />
            </Box>
            <Typography className="title">{data?.title}</Typography>
          </Grid>
          <Grid item xs={12} className="footer">
            <Grid container justifyContent="center">
              <Grid item xs={12}>
                <Typography className="details">
                  {data?.category?.name}
                </Typography>
              </Grid>
              <EventCategory
                event={data}
                label={
                  event.type === "workshop:created"
                    ? "Neuer Workshop"
                    : "Neue Förderung"
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Box className="background-image">
          <StrapiImageAsset
            strapiImageObject={data?.logo}
            layout="fill"
            width={320}
            height={180}
          />
        </Box>
      </Box>
    </Link>
  );
};

const workshopCreatedEventStyles = {
  position: "relative",
  padding: "1rem",
  background:
    "linear-gradient(180deg, #F7F7F7 0%, rgba(247, 247, 247, 0.4) 100%)",
  height: "100%",
  minHeight: "16rem",
  transition: "all 0.2s ease-in-out",
  "&::before": {
    content: "''",
    position: "absolute",
    inset: 0,
    width: "100%",
    height: "100%",
    transition: "all 0.2s ease-in-out",
    opacity: 0,
    background:
      "linear-gradient(180deg, #FCFCFC 0%, rgba(247, 247, 247, 0.1) 100%)",
    outline: `1px solid ${alpha(theme.palette.primary.main, 0.75)}`,
  },
  "&:hover": {
    transform: "translateY(-0.25rem)",
    "&::before": {
      opacity: 1,
    },
  },
  "& .content": {
    zIndex: 1,
    position: "relative",
    padding: "1rem 1rem 0 1rem",
    height: "100%",
    justifyContent: "space-between",
    background:
      "linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.4) 100%), rgba(247, 247, 247, 0.7)",
    backdropFilter: "blur(2rem)",
    WebkitBackdropFilter: "blur(2rem)", // Safari support
    MozBackdropFilter: "blur(2rem)", // Firefox support
    OBackdropFilter: "blur(2rem)", // Opera support
    msBackdropFilter: "blur(2rem)", // IE/Edge support
  },
  "& .workshop-image": {
    mixBlendMode: "multiply",
    width: "8rem",
  },
  "& .title": {
    textAlign: "center",
    mb: "0.5rem",
    color: "common.black",
    fontFamily: basierCirleBold.style.fontFamily,
    fontWeight: basierCirleBold.style.fontWeight,
    textTransform: "uppercase",
    fontSize: { xs: "1rem", lg: "1.15rem", xl: "1.5rem" },
    lineHeight: "1.25",
    textWrap: "balance",
    wordWrap: "break-word",
    hyphens: "auto",
  },
  "& .details": {
    textAlign: "center",
    color: "common.white",
    textTransform: "uppercase",
    fontSize: "0.875rem",
  },
  "& .footer": {
    marginTop: "auto",
  },
  "& .background-image": {
    overflow: "hidden",
    position: "absolute",
    left: "1rem",
    right: "1rem",
    top: "1rem",
    bottom: "1rem",
    width: "calc(100% - 2rem)",
  },
};
