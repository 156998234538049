import { useFeed } from "@/lib/api";
import { Grid } from "@mui/material";
import { motion } from "framer-motion";
import { BandVerifiedEvent } from "./BandVerifiedEvent";
import { ConcertCreatedEvent } from "./ConcertCreatedEvent";
import { DevelopmentProgramCreatedEvent } from "./DevelopmentProgramCreatedEvent";
import { WorkshopCreatedEvent } from "./WorkshopCreatedEvent";

export const EventFeedPage = ({ index = 1 }) => {
  const { events, isLoading } = useFeed(index * 4, 4);

  //if (isLoading) return <EventSkeleton />;

  const renderEvent = (event) => {
    switch (event.type) {
      case "band:verified":
        return <BandVerifiedEvent event={event} />;
      case "developmentprogram:created":
        return <DevelopmentProgramCreatedEvent event={event} />;
      case "concert:created":
        return <ConcertCreatedEvent event={event} />;
      case "workshop:created":
        return <WorkshopCreatedEvent event={event} />;
      default:
        return (
          <>
            {event.type}: {event.object_id}
          </>
        );
    }
  };

  return (
    <>
      {events?.map((event, i) => (
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          key={event.id}
          component={motion.div}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 0.5,
            delay: i * 0.1,
          }}
        >
          {renderEvent(event)}
        </Grid>
      ))}
    </>
  );
};
