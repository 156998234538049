import { ImageAsset } from "@/components/ImageAsset";
import { useBandById } from "@/lib/api";
import { darkenColor, responsiveImage } from "@/lib/helper";
import { basierCirleBold } from "@/styles/fonts";
import theme from "@/styles/theme";
import { Box, Grid, Typography } from "@mui/material";
import { alpha } from "@mui/material/styles";
import { FastAverageColor } from "fast-average-color";
import Link from "next/link";
import { useEffect, useState } from "react";
import EventCategory from "./EventCategory";

export const BandVerifiedEvent = ({ event }) => {
  const { band, isLoading, isError } = useBandById(event.object_id);
  const [color, setColor] = useState(null);

  const fac = new FastAverageColor();

  useEffect(() => {
    if (band?.photo?.formats) {
      fac.getColorAsync(responsiveImage(band?.photo?.formats, 32)).then((c) => {
        const rgbString = c.rgb.slice(4, -1);
        const darkenedColor = darkenColor(rgbString, 40);
        setColor(darkenedColor);
      });
    } else {
      setColor(theme.palette.common.black);
    }
  }, [band?.photo?.formats]);

  if (isLoading || isError || !band) return <></>;

  return (
    <Link href={`/musik/${band.slug}`} style={{ textDecoration: "none" }}>
      <Box sx={bandVerifiedEventStyles(color)}>
        <Grid container justifyContent="center" className="content">
          <Grid item xs={12}>
            <Box className="image">
              <ImageAsset
                url={responsiveImage(band?.photo?.formats, 1200)}
                layout="fill"
              />
              <Box className="image-overlay" />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography className="title">{band?.name}</Typography>
            <Typography className="details">
              {band?.genres?.[0]?.title} aus {band?.zipcode?.label}
            </Typography>
          </Grid>
          <Grid item xs="auto">
            <EventCategory event={event} label="Neue Band" />
          </Grid>
        </Grid>
      </Box>
    </Link>
  );
};

const bandVerifiedEventStyles = (color) => ({
  position: "relative",
  padding: "1rem",
  background: `linear-gradient(180deg, rgba(26, 26, 26, 0.6) 0%, rgba(26, 26, 26, 0) 50%), rgba(${color}, 1)`,
  height: "100%",
  transition: "all 0.2s ease-in-out",
  "&::before": {
    content: "''",
    position: "absolute",
    inset: 0,
    width: "100%",
    height: "100%",
    background: `linear-gradient(180deg, rgba(50, 50, 50, 1) 0%, rgba(50, 50, 50, 0) 50%), rgba(${color}, 1)`,
    transition: "all 0.2s ease-in-out",
    opacity: 0,
    outline: `1px solid ${alpha(theme.palette.secondary.main, 0.5)}`,
  },
  "&:hover": {
    transform: "translateY(-0.25rem)",
    "&::before": {
      opacity: 1,
    },
  },
  "& .content": {
    position: "relative",
  },
  "& .image": {
    position: "relative",
    aspectRatio: "1/1",
  },
  "& .image-overlay": {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: `linear-gradient(180deg, rgba(${color}, 0) 50%, rgba(${color}, 1) 100%)`,
  },
  "& .title": {
    textAlign: "center",
    mb: "0.5rem",
    color: "common.white",
    fontFamily: basierCirleBold.style.fontFamily,
    fontWeight: basierCirleBold.style.fontWeight,
    textTransform: "uppercase",
    fontSize: "1.625rem",
    lineHeight: "1.1",
    textWrap: "balance",
    textOverflow: "ellipsis",
    width: "100%",
    overflow: "hidden",
    lineClamp: "3",
    display: "-webkit-box",
    "-webkit-line-clamp": "3",
    "-webkit-box-orient": "vertical",
  },
  "& .details": {
    textAlign: "center",
    color: "common.white",
    textTransform: "uppercase",
    fontSize: "0.875rem",
  },
  "& .event-type": {
    color: "common.white",
    background: theme.palette.secondary.main,
    fontSize: "0.75rem",
    padding: "0.25rem 0.75rem",
    margin: "1rem 0",
    height: "1.375rem",
    "& .MuiChip-label": {
      padding: 0,
    },
  },
});
