import { useRegion, useRegions } from "@/lib/api";
import { Button } from "@mui/material";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import AutocompleteStyled from "./AutocompleteStyled";

export const RegionSelector = ({
  name = "region",
  onChange,
  variant,
  label,
  placeholder,
  value,
}) => {
  const [fatherRegion, setFatherRegion] = useState(null);
  const [region, setRegion] = useState(null);
  const { region: loadedRegion } = useRegion(value);
  const { regions, isLoading, isError, mutate } = useRegions(
    value ? loadedRegion?.father : fatherRegion,
  );

  useEffect(() => {
    if (!isLoading && value) {
      for (const r of regions) {
        if (r.id === value + "") {
          const event = {
            target: {},
          };
          handleSelected(event, r);
          break;
        }
      }
    }
  }, [isLoading]);

  const handleSelected = (event, value) => {
    event.target.name = name;
    event.target.value = value?.id;

    setFatherRegion(value);
    setRegion(value);
    mutate();

    if (onChange) {
      onChange(event, value);
    }
  };

  const changeToFather = (event) => {
    event.target.name = name;
    event.target.value = fatherRegion.father;

    setFatherRegion(fatherRegion.father);
    setRegion(fatherRegion.father);
    mutate();

    if (onChange) {
      onChange(event, fatherRegion.father);
    }
  };

  const PaperComponentRegionSelector = (options) => {
    const { props, ref, containerProps, children } = options;

    return (
      <Paper {...containerProps}>
        {regions?.length === 0 ? (
          <></>
        ) : (
          <>
            <Fade in>
              <Box ref={ref} {...props}>
                {children}
              </Box>
            </Fade>
            <Button fullWidth variant="contained" color="secondary">
              {fatherRegion?.name.length > 0 ? (
                <>{fatherRegion.name} auswählen</>
              ) : (
                "Alle Regionen (NRW) auswählen"
              )}
            </Button>
          </>
        )}
      </Paper>
    );
  };

  return (
    <>
      <AutocompleteStyled
        name={name}
        variant={variant}
        label={label}
        options={regions}
        value={region}
        placeholder={placeholder}
        onChange={handleSelected}
        fatherRegion={fatherRegion}
        changeToFather={changeToFather}
        isOptionEqualToValue={(option, value) => {
          return option?.id === value?.id;
        }}
        getOptionLabel={(option) => `${option.name}`}
        disableCloseOnSelect={true}
        PaperComponent={PaperComponentRegionSelector}
      />
    </>
  );
};
