import { ImageAsset } from "@/components/ImageAsset";
import { useEvent } from "@/lib/api";
import { responsiveImage } from "@/lib/helper";
import { basierCirleBold } from "@/styles/fonts";
import theme from "@/styles/theme";
import { Box, Grid, Typography } from "@mui/material";
import { alpha } from "@mui/material/styles";
import Link from "next/link";
import EventCategory from "./EventCategory";

export const ConcertCreatedEvent = ({ event }) => {
  const { event: concert, isLoading, isError } = useEvent(event.object_id);

  if (isLoading || isError || !concert) return <></>;

  return (
    <Link
      href={`/musik/${concert?.bands[0].slug}`}
      style={{ textDecoration: "none" }}
    >
      <Box sx={concertCreatedEventStyles}>
        <Box className="image">
          <ImageAsset
            url={responsiveImage(concert?.bands[0]?.photo?.formats, 800)}
            layout="fill"
          />
          <Box className="image-overlay" />
        </Box>
        <Grid container justifyContent="center" className="content">
          <Grid item xs={12}>
            <Grid container alignItems="center" spacing={3}>
              <Grid item xs={3} textAlign="center">
                {concert?.date_start && (
                  <Box className="date">
                    <Typography className="date-day">
                      {new Date(concert.date_start).getDate()}
                    </Typography>
                    <Typography className="date-month">
                      {new Date(concert.date_start).toLocaleString("de-DE", {
                        month: "short",
                      })}
                    </Typography>
                  </Box>
                )}
              </Grid>
              <Grid item xs={9}>
                <Typography className="time-start">
                  {concert?.time_start &&
                    new Date(concert.time_start)
                      .toLocaleTimeString("de-DE", {
                        hour: "2-digit",
                        minute: "2-digit",
                      })
                      .replace(".", ":")}{" "}
                  Uhr
                </Typography>
                <Typography className="details">
                  {concert?.location?.name},{" "}
                  {concert.location?.zipcode?.label || concert.zipcode?.label}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "auto" }}>
            <Grid container justifyContent="center">
              <Grid item xs={12}>
                <Typography className="title">{concert?.name}</Typography>
                <Typography className="subtitle">
                  mit{" "}
                  <span>
                    {concert?.bands?.map((band) => band.name).join(", ")}
                  </span>
                </Typography>
              </Grid>
              <Grid item xs="auto">
                <EventCategory event={event} label="Neues Event" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Link>
  );
};

const concertCreatedEventStyles = {
  position: "relative",
  padding: "1rem",
  background: `linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%), ${theme.palette.secondary.main}`,
  height: "100%",
  minHeight: "16rem",
  transition: "all 0.2s ease-in-out",
  "&::before": {
    content: "''",
    position: "absolute",
    inset: 0,
    width: "100%",
    height: "100%",
    transition: "all 0.2s ease-in-out",
    opacity: 0,
    outline: `1px solid ${alpha(theme.palette.primary.main, 0.75)}`,
  },
  "&:hover": {
    transform: "translateY(-0.25rem)",
    "&::before": {
      opacity: 1,
    },
    "& .image": {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      width: "100%",
      height: "100%",
    },
  },
  "& .content": {
    padding: "1rem 1rem 0 1rem",
    zIndex: 1,
    position: "relative",
    height: "100%",
    justifyContent: "space-between",
  },
  "& .date": {
    transform: "translateY(0.15rem)",
    position: "relative",
    marginRight: "0.75rem",
  },
  "& .date-day": {
    fontSize: "1.5rem",
    fontWeight: basierCirleBold.style.fontWeight,
    fontFamily: basierCirleBold.style.fontFamily,
    color: "common.white",
  },
  "& .date-month": {
    fontSize: "1rem",
    color: "common.white",
    textTransform: "uppercase",
    marginTop: "0.25rem",
  },
  "& .time-start": {
    fontSize: "0.875rem",
    color: "common.white",
    fontFamily: basierCirleBold.style.fontFamily,
    fontWeight: basierCirleBold.style.fontWeight,
  },
  "& .details": {
    color: "common.white",
    textTransform: "uppercase",
    fontSize: "0.875rem",
    lineHeight: "1",
    fontFamily: basierCirleBold.style.fontFamily,
    fontWeight: basierCirleBold.style.fontWeight,
    lineClamp: 2,
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
    overflow: "hidden",
    textOverflow: "ellipsis",
    wordWrap: "break-word",
    hyphens: "auto",
    textWrap: "balance",
  },
  "& .image": {
    position: "absolute",
    top: "1rem",
    left: "1rem",
    right: "1rem",
    bottom: "1rem",
    width: "calc(100% - 2rem)",
    height: "calc(100% - 2rem)",
    transition: "all 0.2s ease-in-out",
    "& img": {
      filter: "grayscale(1)",
    },
  },
  "& .image-overlay": {
    position: "absolute",
    inset: 0,
    width: "100%",
    height: "100%",
    background: `linear-gradient(180deg, ${alpha(theme.palette.secondary.main, 0)} 50%, ${theme.palette.secondary.main} 100%),
       linear-gradient(0deg, ${alpha(theme.palette.secondary.main, 0.8)}, ${alpha(theme.palette.secondary.main, 0.8)}),
       linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.6) 100%)`,
  },
  "& .title": {
    mb: "0.5rem",
    color: "common.white",
    fontFamily: basierCirleBold.style.fontFamily,
    fontWeight: basierCirleBold.style.fontWeight,
    textTransform: "uppercase",
    fontSize: { xs: "1rem", lg: "1.25rem", xl: "1.625rem" },
    lineHeight: "1.25",
    textWrap: "balance",
    wordWrap: "break-word",
    hyphens: "auto",
  },
  "& .subtitle": {
    color: "common.white",
    fontSize: "0.875rem",
    textWrap: "balance",
    wordWrap: "break-word",
    hyphens: "auto",
    textTransform: "uppercase",
    "& span": {
      fontFamily: basierCirleBold.style.fontFamily,
      fontWeight: basierCirleBold.style.fontWeight,
    },
  },
  "& .footer": {
    marginTop: "auto",
  },
};
