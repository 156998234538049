import EventFeed from "@/components/eventfeed/EventFeed";
import { GenreRegionFilter } from "@/components/GenreRegionFilter";
import { Hero } from "@/components/Hero";
import { HomepageCTABox } from "@/components/homepage/HomepageCTABox";
import NewsTeaserDisplay from "@/components/news/NewsTeaserDisplay";
import PageHead from "@/components/PageHead";
import { TeaserboxFullwidth } from "@/components/TeaserboxFullwidth";
import { TopPlaylist } from "@/components/TopPlaylist";
import { TopSong } from "@/components/TopSong";
import theme from "@/styles/theme";
import Box from "@mui/material/Box";
import { grey } from "@mui/material/colors";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

export default function Home({ page, articles }) {
  return (
    <>
      <PageHead page={page} />

      <main>
        <Paper>
          <div dangerouslySetInnerHTML={{ __html: page?.content_html }} />
        </Paper>

        <Hero content={page} />

        {/* BEGIN 4 BOXES */}
        <Container
          maxWidth="false"
          disableGutters={true}
          sx={{
            bgcolor: grey[900],
          }}
        >
          <Container maxWidth="xl">
            <Grid container justifyContent="center">
              <Grid item xs={12} sm={10}>
                <Box
                  sx={{
                    display: "grid",
                    gap: "8px",
                    gridAutoRows: "auto",
                    gridTemplateColumns: "auto",
                    [theme.breakpoints.up("md")]: {
                      pt: 2,
                      pb: 2.5,
                      gridTemplateColumns: "repeat(3, 1fr)",
                      gap: "24px",
                    },
                    [theme.breakpoints.up("xl")]: {
                      pt: 0,
                      pb: 0,
                      gridTemplateColumns: "repeat(4, 1fr)",
                    },
                    [theme.breakpoints.down("md")]: {
                      "& div:nth-of-type(1)": {
                        order: "2",
                      },
                      "& div:nth-of-type(3)": {
                        order: "3",
                      },
                      "& div:nth-of-type(5)": {
                        order: "1",
                      },
                    },
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      marginY: 2,
                      textAlign: "center",
                      display: { xs: "block", md: "none" },
                    }}
                  >
                    Musik aus deiner Region
                  </Typography>
                  <TopSong />
                  <TopPlaylist />
                  <GenreRegionFilter />
                  <HomepageCTABox />
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Container>
        {/* END 4 BOXES */}

        {/* BEGIN CONTENT TEASER */}
        <Container maxWidth="false" disableGutters={true}>
          <Container
            maxWidth="xl"
            sx={{
              mt: 8,
              mb: 4,
              [theme.breakpoints.up("sm")]: {
                my: 12,
              },
            }}
          >
            <Grid
              container
              justifyContent="center"
              sx={{ position: "relative", zIndex: "99" }}
            >
              <Grid item xs={12} sm={8}>
                <Typography variant="h1" align="center" sx={{ mb: 4 }}>
                  {page?.headline}
                </Typography>
                <Typography
                  variant="subtitle1"
                  component="p"
                  align="center"
                  sx={{ mb: 6 }}
                >
                  {page?.introduction}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={10} sx={{ mb: 6 }}>
                <EventFeed />
              </Grid>
              <Grid
                item
                xs={12}
                sm={10}
                sx={{
                  position: "relative",
                  "& > div:nth-of-type(1)::before": {
                    content: "''",
                    background: "url(/img/line-02-orange.svg)",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    width: "75%",
                    height: "100%",
                    position: "absolute",
                    bottom: "0",
                    left: "0",
                    zIndex: "1",
                    mb: "-2rem",
                    [theme.breakpoints.up("sm")]: {
                      ml: "-3rem",
                      mb: "-5rem",
                      backgroundPosition: "center",
                    },
                    [theme.breakpoints.up("lg")]: {
                      ml: "-3rem",
                      mb: "-2rem",
                      backgroundPosition: "bottom",
                    },
                  },
                  "& > div:nth-of-type(4)::before": {
                    content: "''",
                    background: "url(/img/line-12-orange.svg)",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "bottom",
                    width: "50%",
                    height: "100%",
                    mt: "-4rem",
                    mr: "-.5rem",
                    position: "absolute",
                    top: "0",
                    right: "0",
                    zIndex: "1",
                    [theme.breakpoints.up("sm")]: {
                      width: "35%",
                      height: "100%",
                      mr: "-3rem",
                      mt: "-8rem",
                      backgroundPosition: "bottom",
                    },
                    [theme.breakpoints.up("lg")]: {
                      width: "25%",
                      height: "100%",
                      mr: "-3rem",
                      mt: "-8rem",
                      backgroundPosition: "center",
                    },
                  },
                  "& div:nth-of-type(2n)": {
                    flexDirection: "row-reverse",
                  },
                  "& > div": {
                    [theme.breakpoints.up("sm")]: {
                      bgcolor: grey[900],
                    },
                  },
                }}
              >
                {page?.Teaserbox_Fullwidth?.map((box, index) => {
                  return <TeaserboxFullwidth key={index} content={box} />;
                })}
              </Grid>
            </Grid>
          </Container>
        </Container>
        {/* END CONTENT TEASER */}

        {/* BEGIN NEWS TEASER */}
        <Container
          maxWidth="false"
          disableGutters={true}
          sx={{
            bgcolor: grey[900],
            mt: 8,
            py: 3,
            [theme.breakpoints.up("sm")]: {
              mt: 14,
              py: 6,
            },
          }}
        >
          <Typography variant="h3" align="center">
            Aktuelles
          </Typography>
          <Container
            maxWidth="xl"
            sx={{
              mt: 3,
              [theme.breakpoints.up("sm")]: {
                my: 6,
              },
            }}
          >
            <Grid
              container
              justifyContent="center"
              sx={{
                bgcolor: theme.palette.common.white,
                [theme.breakpoints.only("xs")]: {
                  p: 3,
                },
                [theme.breakpoints.up("sm")]: {
                  py: 13,
                },
              }}
            >
              <Grid item xs={12} sm={10}>
                <NewsTeaserDisplay articles={articles} />
              </Grid>
            </Grid>
          </Container>
        </Container>
        {/* END NEWS TEASER */}
      </main>
    </>
  );
  SSG_REVALIDATE_BANDS;
}

export const getStaticProps = async () => {
  const baseUrl = `${
    process.env.NEXT_PUBLIC_API_URL || "http://localhost:1337"
  }`;
  const pageResponse = await fetch(`${baseUrl}/home`);
  const page = await pageResponse.json();

  const newsResponse = await fetch(
    `${baseUrl}/articles?_sort=date_display:DESC&_limit=3`,
  );
  const articles = await newsResponse.json();

  return {
    props: {
      page,
      articles,
    },
    revalidate: parseInt(process.env.SSG_REVALIDATE_TIMEOUT),
  };
};
